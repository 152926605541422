<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row class="match-height">
        <!-- list -->
        <b-col
          lg="12"
        >
          <b-overlay
            :show="ShowLog"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <b-icon-controller
                  font-scale="3"
                  animation="cylon"
                />
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
            <b-tabs
              pills
              align="center"
              style="background-color: #fff;"
              class="p-1"
            >
              <b-tab
                title="รายการแทงทั้งหมด"
                active
              >
                <b-card no-body>
                  <b-card-body>
                    <div class="p-1">
                      <h3>รายการแทงทั้งหมด</h3>
                    </div>
                  </b-card-body>
                  <b-table

                    striped
                    hover
                    responsive
                    class="position-relative items-center"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                  >
                    <template #cell(index)="data">
                      {{ perPage * (currentPage - 1) + (data.index + 1) }}
                    </template>
                    <template #cell(status)="data">
                      <b-badge
                        :variant="
                          data.item.status === 'confirm' ? 'success' : 'danger'
                        "
                        class="badge-glow"
                      >
                        {{ data.item.status === 'confirm' ? 'ยืนยัน' : 'ยกเลิก' }}
                      </b-badge>
                    </template>
                    <template #cell(amount)="data">
                      {{ Commas(data.item.amount) + ' บาท' }}
                    </template>
                    <template #cell(actions)="data">
                      <div class="text-nowrap">
                        <feather-icon
                          :id="`invoice-row-${data.item.username}-preview-icon`"
                          icon="FileTextIcon"
                          size="16"
                          class="mx-1"
                          @click="$router.push({ name: `lotto-list-detail`, params: { id: data.item._id } })"
                        />
                        <b-tooltip
                          title="ดูรายละเอียดโพย"
                          :target="`invoice-row-${data.item.username}-preview-icon`"
                        />
                      </div>
                    </template>
                  </b-table>

                  <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                    <!-- page length -->
                    <b-form-group
                      label="Per Page"
                      label-cols="8"
                      label-align="left"
                      label-size="sm"
                      label-for="sortBySelect"
                      class="text-nowrap mb-md-0 mr-1 ml-1 pb-1"
                    >
                      <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        inline
                        :options="pageOptions"
                        @change="GetLottolist()"
                      />
                    </b-form-group>

                    <!-- pagination -->
                    <div>
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                        @input="GetLottolist()"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </b-card-body>
                </b-card>

              </b-tab>

              <b-tab
                title="รายการที่ถูกยกเลิก"
              >
                <b-card no-body>
                  <b-card-body>
                    <div class="p-1">
                      <h3>รายการที่ถูกยกเลิก</h3>
                    </div>
                  </b-card-body>
                  <b-table
                    striped
                    hover
                    responsive
                    class="position-relative items-center"
                    :per-page="perPage"
                    :items="itemsLog"
                    :fields="fieldsLog"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                  >
                    <template #cell(index)="data">
                      {{ perPage * (currentPageLog - 1) + (data.index + 1) }}
                    </template>
                    <template #cell(status)="data">
                      <b-badge
                        :variant="
                          data.item.status === 'confirm' ? 'success' : 'danger'
                        "
                        class="badge-glow"
                      >
                        {{ data.item.status === 'confirm' ? 'ยืนยัน' : 'ยกเลิก' }}
                      </b-badge>
                    </template>
                    <template #cell(amount)="data">
                      {{ Commas(data.item.amount) + ' บาท' }}
                    </template>
                    <template #cell(actions)="data">
                      <div class="text-nowrap">
                        <feather-icon
                          :id="`invoice-row-${data.item.username}-preview-icon`"
                          icon="FileTextIcon"
                          size="16"
                          class="mx-1"
                          @click="$router.push({ name: `lotto-list-detail`, params: { id: data.item._id } })"
                        />
                        <b-tooltip
                          title="ดูรายละเอียดโพย"
                          :target="`invoice-row-${data.item.username}-preview-icon`"
                        />
                      </div>
                    </template>
                  </b-table>

                  <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                    <!-- page length -->
                    <b-form-group
                      label="Per Page"
                      label-cols="8"
                      label-align="left"
                      label-size="sm"
                      label-for="sortBySelect"
                      class="text-nowrap mb-md-0 mr-1 ml-1 pb-1"
                    >
                      <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        inline
                        :options="pageOptions"
                        @change="GetCancle()"
                      />
                    </b-form-group>

                    <!-- pagination -->
                    <div>
                      <b-pagination
                        v-model="currentPageLog"
                        :total-rows="totalRowsLog"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                        @input="GetCancle()"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </b-card-body>
                </b-card>

              </b-tab>

            </b-tabs>
          </b-overlay>
        </b-col>

      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  // BFormInput,
  // BButton,
  BPagination,
  BTable,
  // BInputGroupPrepend,
  BFormSelect,
  // BProgress,
  BBadge,
  BTabs,
  BTab,
  BOverlay,
  BIconController,

} from 'bootstrap-vue'
// import moment from 'moment-timezone'
// import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
// import moment from 'moment-timezone'
// import {
//   required, integer, between, length,
// } from '@validations'

export default {
  components: {
    // vSelect,
    // BInputGroupPrepend,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BTable,
    ValidationObserver,
    // BProgress,
    BBadge,
    BTabs,
    BTab,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      ShowCancle: false,
      ShowList: false,
      totalRows: 1,
      currentPage: 1,
      items: [],
      totalRowsLog: 1,
      currentPageLog: 1,
      itemsLog: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      pageOptions: [10, 15, 20],
      perPage: 10,
      fields: [
        { key: 'index', label: 'ลำดับที่' },
        { key: '_id', label: 'โพยที่' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'LottoHeadName', label: 'ประเภทหวย' },
        { key: 'CloseDateName', label: 'งวดประจำวันที่' },
        { key: 'amount', label: 'เงินเดิมพัน' },
        { key: 'remark', label: 'ประเภทลูกค้า' },
        { key: 'status', label: 'สถานะ' },
        { key: 'timestamp', label: 'เวลาแทง' },
        { key: 'actions', label: 'Actions' },
      ],
      fieldsLog: [
        { key: 'index', label: 'ลำดับที่' },
        { key: '_id', label: 'โพยที่' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'LottoHeadName', label: 'ประเภทหวย' },
        { key: 'CloseDateName', label: 'งวดประจำวันที่' },
        { key: 'amount', label: 'เงินเดิมพัน' },
        { key: 'remark', label: 'ประเภทลูกค้า' },
        { key: 'status', label: 'สถานะ' },
        { key: 'timestamp', label: 'เวลาแทง' },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.GetLottolist()
    this.GetCancle()
  },
  methods: {
    GetLottolist() {
      // const index = 0
      this.ShowList = true
      const params = {
        page: this.currentPage,
        perpage: this.perPage,
      }
      this.$http
        .post(
          'https://api.rmtlotto.com/api/history/LottoList',
          params,
        )
        .then(response => {
          this.onFiltered(response.data)
          this.ShowList = false
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
          this.ShowList = false
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.Mes
      this.totalRows = filteredItems.Mes.length
      console.log(this.items)
    },
    GetCancle() {
      // const index = 0
      this.ShowCancle = true
      const params = {
        page: this.currentPageLog,
        perpage: this.perPage,
      }
      this.$http
        .post(
          'https://api.rmtlotto.com/api/history/LottoCancelList',
          params,
        )
        .then(response => {
          this.onFilteredLog(response.data)
          this.ShowCancle = false
        })
        .catch(error => {
          console.log(error)
          this.ShowCancle = false
        })
    },
    onFilteredLog(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.itemsLog = filteredItems.Mes
      this.totalRowsLog = filteredItems.total
    },
    Commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
